import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ChakraProvider, Spinner } from "@chakra-ui/react";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Capacitor } from "@capacitor/core";

const App = React.lazy(() => import("@/App.tsx"));
const About = React.lazy(() => import("@/routes/About.tsx"));
const Compose = React.lazy(() => import("@/routes/Compose.tsx"));
const ChordAnalysis = React.lazy(() => import("@/routes/ChordAnalysis.tsx"));
const Welcome = React.lazy(() => import("@/routes/Welcome.tsx"));
const AllChordsGraph = React.lazy(() => import("@/routes/AllChordsGraph.tsx"));
const Search = React.lazy(() => import("@/routes/Search.tsx"));

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://fea4b6472bb6c405250ece248c191a0c@o4507504511090688.ingest.us.sentry.io/4507504517251072",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/guida-app\.vercel\.app/,
    /^https:\/\/guida-app\.jonathangoya\.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

import "./index.css";

import theme from "./theme/theme.ts";

const Loading = () => {
  return (
    <>
      <style>{`
    #root{
        place-items: center;
        justify-content: center;
    }
    `}</style>
      <Spinner
        thickness="3px"
        speed="2s"
        emptyColor="gray.200"
        color="#a57a7a"
        size="xl"
      />
    </>
  );
};

const isNativeAppOrPWA = () => {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    Capacitor.isNativePlatform()
  );
};
document.documentElement.setAttribute("is-app", isNativeAppOrPWA().toString());
console.log("is app: " + isNativeAppOrPWA().toString());

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Welcome />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/compose",
        element: <Compose />,
      },
      {
        path: "/chords",
        element: <ChordAnalysis />,
      },
      {
        path: "/allchordsgraph",
        element: <AllChordsGraph />,
      },
      {
        path: "/search",
        element: <Search />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Analytics />
      <SpeedInsights />
      <React.Suspense fallback={<Loading />}>
        <RouterProvider router={router} />
      </React.Suspense>
    </ChakraProvider>
  </React.StrictMode>
);
