import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)


const sm = defineStyle({
    fontSize: 'sm',
    p: '0em',
    h: '100%',
  })
  

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: sm,
  addon: sm


})

export const inputTheme = defineMultiStyleConfig({ baseStyle })