import { tableAnatomy } from "@chakra-ui/anatomy";

import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";



const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const xs = defineStyle({
  fontSize: "xs",
  px: "0.2em",
  h: "1em",
});

const sm = defineStyle({
  fontSize: "sm",
  px: "0.1em",
  h: "0.5em",
});
  
const sizes = {
  xs: definePartsStyle({ th: xs, td: xs, caption: xs }),
  sm: definePartsStyle({ th: sm, td: sm, caption: sm }),
};
  
const tableTheme = defineMultiStyleConfig({ sizes });

export default tableTheme;