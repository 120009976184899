// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { extendTheme, type ThemeConfig } from "@chakra-ui/react";
import "@fontsource/playfair/600.css";
import "@fontsource/playfair/600-italic.css";
import tableTheme from "./table";
import tagTheme from "./tag";
import { inputTheme } from "./input";

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  fonts: {
    heading: `'Playfair', serif`,
  },
  fontSizes: {
    "2xs": "1em",
    xst: "0.4em",
    xs: "1.5em",
    sm: "2.0em",
    md: "2.5em",
    lg: "3em",
    xl: "3.5em",
    "2xl": "4em",
    "3xl": "5em",
    "4xl": "6em",
    "5xl": "8em",
    "6xl": "10em",
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  lineHeights: {
/*     normal: "normal",
    none: "2em",
    shorter: "2.5em",
    short: "2.75em",
    base: "3em",
    tall: "3.5em",
    taller: "4em",  */
  },
  letterSpacings: {
/*     tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em", */
  },
  sizes: {
/*     full: "100%",
    "0": "0",
    "1": "1em",
    "2": "2em",
    "3": "3em",
    "4": "4em",
    "5": "5em",
    "6": "3em",  */
    "8": "2em",
/*     "10": "2.5em",
    "12": "3em",
    "16": "4em",
    "20": "5em",
    "24": "6em",
    "32": "8em",
    "40": "10em",
    "48": "12em",
    "56": "14em",
    "64": "16em", */
/*    "3xs": "14em",
    "2xs": "16em",
    xs: "20em",
    sm: "24em",
    md: "28em",
    lg: "32em",
    xl: "36em",
    "2xl": "42em",
    "3xl": "48em",
    "4xl": "56em",
    "5xl": "64em",
    "6xl": "72em", */
  },
  space: {
    px: "1px",
    "0": "0",
    "1": "0.25em",
    "2": "0.5em",
    "3": "0.75em",
    "4": "1em",
    "5": "1.25em",
    "6": "1.5em",
    "8": "2em",
    "10": "2.5em",
    "12": "3em",
    "16": "4em",
    "20": "5em",
    "24": "6em",
    "32": "8em",
    "40": "10em",
    "48": "12em",
    "56": "14em",
    "64": "16em",
  },
  components: {
    Table: tableTheme,
    Tag: tagTheme,
    Text: {
      sizes: {
        md: {
          fontSize: "4em",
        },
      },
    },
    td: {
      fontSize: "1em",
    },
    Input: inputTheme,

    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: "bold", // Normally, it is "semibold"
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        xs: {
          h: "2em",
          fontSize: "2em",
          px: "0.5em",
        },

        sm: {
          h: "2.5em",
          fontSize: "2.5em",
          px: "0.5em",
        },
        md: {
          h: "3em",
          fontSize: "4em",
          px: "0.8em",
        },
      },
    },
  },
});

export default theme;
