import { tagAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys)


const baseStyle = definePartsStyle({
// define the part you're going to style
    label: {
        //lineHeight: "0.5em",
        //fontSize: "fontSizes.xst",
        fontSize: "0.6em"

    },
    closeButton: {
        fontSize: "0.6em",
        //w: "0.2em",
        //h: "0.2em",

    }

})
  
const tagTheme = defineMultiStyleConfig({ baseStyle })

export default tagTheme;